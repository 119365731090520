import { Component, inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'dialog-confirmation',
  templateUrl: 'confirmation.dialog.html',
  styleUrls: ['confirmation.dialog.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
  ],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ConfirmationDialog implements OnInit {
  public dialogRef = inject<MatDialogRef<ConfirmationDialog>>(
    MatDialogRef<ConfirmationDialog>
  );
  public data = inject<ConfirmationData>(MAT_DIALOG_DATA);

  ngOnInit() {
    this.data = {
      ...INITIAL_DATA,
      ...this.data,
    };
  }

  noClick(): void {
    this.dialogRef.close(false);
  }
  noConfirm(): void {
    this.dialogRef.close(true);
  }
}

export class ConfirmationData {
  title!: string;
  subTitle!: string;
  cancel?: string;
  confirm?: string;
  result?: boolean;
  hideCancel?: boolean;
  actions?: {
    confirm?: {
      show?: boolean;
    };
    cancel?: {
      show?: boolean;
    };
  };
}

const INITIAL_DATA: Partial<ConfirmationData> = {
  actions: {
    confirm: {
      show: true,
    },
    cancel: {
      show: true,
    },
  },
};

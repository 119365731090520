<mat-card
  appearance="outlined"
  class="!border-0"
>
  <mat-card-header>
    <mat-card-title class="card__title">
      <mat-icon color="warn">error_outline</mat-icon>
      <h5 class="main-header whitespace-pre-line my-0">
        {{ data.title | translate }}
      </h5>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p class="whitespace-pre-line">{{ data.subTitle | translate }}</p>
  </mat-card-content>
  <mat-card-actions class="confirmation__dialog--actions">
    <div class="flex items-center justify-end gap-2">
      @if (!data?.hideCancel) {
        <button
          mat-flat-button
          color="warn"
          (click)="noClick()"
        >
          {{ (data.cancel ? data.cancel : 'shared.cancel') | translate }}
        </button>
      }
      @if (data?.actions?.confirm?.show) {
        <button
          type="submit"
          mat-flat-button
          [mat-dialog-close]="data"
          cdkFocusInitial
          color="primary"
          (click)="noConfirm()"
        >
          <mat-icon>check</mat-icon>
          {{ (data.confirm ? data.confirm : 'shared.confirm') | translate }}
        </button>
      }
    </div>
  </mat-card-actions>
</mat-card>
